import lprTapwaySettingService from "@/services/lpr/tapway/setting.service";

export const lprTapwaySetting = {
    namespaced: true,
    actions: {
        updateTapwaySettings({ commit }, request) {
            return lprTapwaySettingService.updateTapwaySettings(request)
            .then(
                response => { 
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {},
};