import moment from 'moment-timezone';

export class DateTimeClass {

    /**
     * Get today datetime in UTC
     * @return object
     */
    static getTodayDateTimeInUTC() {
        return moment().utc();
    }

    /**
     * Default timezone for app
     * @return string
     */
    static getTimeZone() {
        return moment.tz.guess(true);
    }

    /**
     * @returns string
     */
    static getDefaultTimezone() {
        return 'Asia/Kuala_Lumpur';
    }

    /**
     * @param dateObj
     * @param string
     * @return bool
     */
    static validateDateTime(dt, format) {
        return moment(dt, format, true).isValid();
    }

    /**
     * Convert UTC to site time zone 
     * @param string    $dt
     * @param string    $siteTz
     * @param string    $fromFormat
     * @param string    $toFormat
     * @return string
     */
    static convertUTCToTimezone(dt, tz='Asia/Kuala_Lumpur', fromFormat='YYYY-MM-DD HH:mm:ss', toFormat='DD/MM/YYYY HH:mm:ss') {
        if(this.validateDateTime(dt, fromFormat)) {
            return moment.utc(dt, fromFormat).tz(tz).format(toFormat); 
        }
        return '';
    }
}