import api from '@/services/api';

class VmsSettingService {
   
    generalSettings(request) {
        return api.get('/general-settings/vms');
    }

    visitType() {
        return api.get('/vms/settings/visit-type');
    }

    subModule() {
        return api.get('/vms/settings/sub-module');
    }

    createVisitType(request) {
        return api.post('/vms/settings/visit-type', request);
    }

    updateGeneralSettings(request) {
        return api.put('/general-settings/vms?site='+request.site, request.body);
    }

    updateVisitType(request) {
        return api.put('/vms/settings/visit-type/'+request.id, request.body);
    }

    updateSubModule(request) {
        return api.put('/vms/settings/sub-module/'+request.id, request.body);
    }
}

export default new VmsSettingService();