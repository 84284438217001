import { toRefs, reactive, ref, provide } from "vue";
import { useStore } from 'vuex';
import eventBus from "@/common/event-bus";

export default function manipulatePaginatorList(defaultInputs, columnFields) {
  
    let stateName = ref("");
    let currentPage = ref(1);
    let totalPage = ref(0);
    let totalRecords = ref(0);
    let limit = ref(50);
    let inputs = ref({});
   
    provide("currentPage", currentPage);
    provide("totalPage", totalPage);
    provide("totalRecords", totalRecords);
    provide("limit", limit);
    provide("inputs", inputs);

    const store = useStore();

    const paginator = reactive({
        pageList: [],
        pageParams: {},
        pageInfo: {
            limit: limit.value,
            startIndex: 0,
            endIndex: 0,
            order: columnFields[0],
            by: "asc",
            page: currentPage.value,
        },
        pageSearch: {},
        created: []
    })
    
    const setStateName = (name) => {
        stateName.value = name;
    };

    const startup = async () => {
        const paginatorState = getPaginatorState();      

        if(! paginatorState || Object.keys(paginatorState).length == 0) {
            inputs.value = defaultInputs;
        } else {            
            // paginator.pageList = paginatorState.pageList;
            paginator.pageParams = paginatorState.pageParams;
            paginator.pageInfo.startIndex = paginatorState.pageInfo.startIndex;
            paginator.pageInfo.endIndex = paginatorState.pageInfo.endIndex;
            paginator.pageInfo.order = paginatorState.pageInfo.orderBy;
            paginator.pageInfo.by = paginatorState.pageInfo.sort;
            paginator.pageInfo.page = paginatorState.pageInfo.page;
            // paginator.created = paginatorState.created;
            
            totalPage.value = paginatorState.pageInfo.totalPage;
            totalRecords.value = paginatorState.pageInfo.totalRecords;
            currentPage.value = paginatorState.pageInfo.currentPage;
            limit.value = paginatorState.pageInfo.limit;
            inputs.value = paginatorState.pageSearch;
        }
        sendRequest();
    }

    const sendRequest = async () => {
        let result;
        eventBus.$emit("updateLoadingBar", "d-block");

        if(stateName.value == store.getters['vmsSite/getStateName']) {
            result = await fetchVmsSiteActivationList();
        }
        
        if(stateName.value == store.getters['keyCollectionSite/getStateName']) {
            result = await fetchKeyCollectionSiteActivationList();
        }
        
        if(stateName.value == store.getters['lprSite/getStateName']) {
            result = await fetchLprActivationList();
        }
       
        if(result && result.status) {
            if(result.status === 200) {
                paginator.pageList = result.data.data.list;
                paginator.pageInfo.startIndex = result.data.data.paginator.startIndex;
                paginator.pageInfo.endIndex = result.data.data.paginator.endIndex;
                paginator.pageInfo.order = result.data.data.paginator.order;
                paginator.pageInfo.by = result.data.data.paginator.by;
                paginator.pageInfo.limit = result.data.data.paginator.limit;
                paginator.pageInfo.page = result.data.data.paginator.currentPage;
            
                totalPage.value = result.data.data.paginator.totalPage;
                totalRecords.value = result.data.data.paginator.totalRecords;
                currentPage.value = result.data.data.paginator.currentPage;
                limit.value =  result.data.data.paginator.limit;
            } 
            eventBus.$emit("updateLoadingBar", "d-none");
        } else {
            eventBus.$emit("logout")
        }
      
        paginator.created = [];
        eventBus.$emit("updateLoadingBar", "d-none");
    }

    const fetchVmsSiteActivationList = async () => {
        return store.dispatch("paginator/vmsSiteActivationList", { name: stateName.value, params: paginator.pageParams, info: paginator.pageInfo, search: paginator.pageSearch })
        .then(
            response => { return response; },
            error => { return error; }
        );
    }
    
    const fetchKeyCollectionSiteActivationList = async () => {
        return store.dispatch("paginator/keyCollectionSiteActivationList", { name: stateName.value, params: paginator.pageParams, info: paginator.pageInfo, search: paginator.pageSearch })
        .then(
            response => { return response; },
            error => { return error; }
        );
    }
    
    const fetchLprActivationList = async () => {
        return store.dispatch("paginator/lprSiteActivationList", { name: stateName.value, params: paginator.pageParams, info: paginator.pageInfo, search: paginator.pageSearch })
        .then(
            response => { return response; },
            error => { return error; }
        );
    }

    const goSearch = (arg) => {
        paginator.pageParams = arg.params;
        paginator.pageSearch = arg.search;
        paginator.pageInfo.page = 1;
        sendRequest();
    }

    const goToPage = (arg) => {
        paginator.pageInfo.page = arg;
        sendRequest();
    }

    const goSort = (arg) => {
        let sort = "asc";
        if(arg.field == paginator.pageInfo.order) {
            sort = paginator.pageInfo.by == "asc" ?  "desc" : "asc";
        }
        paginator.pageInfo.order = arg.field;
        paginator.pageInfo.by = sort
        sendRequest();
    }

    const getParams = () => {
        return paginator.pageParams;
    }

    const getSortIcon = () => {
        return paginator.pageInfo.by == "asc" ? "arrow-up-short-wide" : "arrow-down-wide-short";
    };

    const getSortColumnIndex = () => {
        return paginator.pageInfo.order;
    }

    const getPaginatorState = () => {
        if(stateName.value == store.getters['vmsSite/getStateName']) {
           return store.getters['paginator/getVmsSiteActivationState'];
        }
        
        if(stateName.value == store.getters['keyCollectionSite/getStateName']) {
           return store.getters['paginator/getKeyCollectionSiteActivationState'];
        }
        if(stateName.value == store.getters['lprSite/getStateName']) {
           return store.getters['paginator/getLprSiteActivationState'];
        }
    }

    return { 
        stateName, 
        ...toRefs(paginator), 
        setStateName, 
        startup, 
        goSearch, 
        goToPage, 
        goSort,
        getParams, 
        getSortIcon,
        getSortColumnIndex,
    };
}