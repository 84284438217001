<template>
  <component :is="appLayout"/>
</template>

<script>
import { computed, onUnmounted, onMounted } from "vue";
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import eventBus from "@/common/event-bus";
import AuthLayout from "@/layouts/AuthLayout"
import MainLayout from "@/layouts/MainLayout"
import displayToastNotification from "@/composables/toast-notification";

export default {
  name: "App",
  components: { AuthLayout, MainLayout },
  setup() {

    const route = useRoute();

    const store = useStore();

    let toastNotification = displayToastNotification();

    const appLayout = computed(function() {
      return (route.meta.layout || AuthLayout )
    })
    
    const logout = () => {
      store.dispatch("auth/forceLogout");
      toastNotification.multiLoginNotification();
    }

    onMounted(() => {
      eventBus.$on("logout", () => { logout(); });
    })

    onUnmounted(() => {
      eventBus.$off("logout");
    })
     
    return {
      appLayout, logout
    }
  }
}
</script>

<style lang="scss">
  @import "./assets/scss/app.scss";
</style>