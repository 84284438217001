import axios from 'axios';
import api from "@/services/api";
import ApiRoute from '@/services/api.route';

class AuthService {

    login(user) {
        return axios.post(ApiRoute.getApiBaseUrl()+'/login', { email: user.email, password: user.password })
        .then(response => {
            if(response.status === 201 && response.data.accessToken) {
                return this.successAuthAccess(response.data);
            } else {
                this.logout();
            }
        });
    }

    logout() {
        return api.get('/logout')
        .then(response => { return response.data; })
        .catch(error => { return error; })
        .finally(() => { this.resetAuthAccess();  })
    }

    forgotPassword(user) {
        return axios.get(ApiRoute.getApiBaseUrl() + '/forgot-password?email=' + user.email);
    }

    resetPassword(body) {
        return axios.put(ApiRoute.getApiBaseUrl() + '/reset-password', { email: body.email, token: body.token, password: body.password, confirmPassword: body.confirmPassword });
    }

    requestRefreshToken(token) {
        return axios.post(ApiRoute.getApiBaseUrl() + '/token', {}, { 
            headers: { "Authorization": "Bearer " + token, "Content-Type": "application/json"}
        });
    }

    successAuthAccess(response) {
        const authAccess = {
            accessToken: response.accessToken,
            tokenType: response.tokenType,
            expiresIn: response.expiresIn,
        }
        localStorage.setItem("authAccess", JSON.stringify(authAccess));
        return authAccess;
    }

    resetAuthAccess() {
        localStorage.removeItem("authAccess");
    }

}

export default new AuthService();
