import api from '@/services/api';

class VmsSiteService {
    view(request) {
        return api.get('/vms/module/'+request.id);
    }

    create(request) {
        return api.post('/vms/module', request);
    }

    updateModule(request) {
        return api.put('/vms/module/'+request.id, request.body);
    }

    activate(request) {
        return api.put('/vms/module/activate/'+request.id);
    }

    deactivate(request) {
        return api.put('/vms/module/deactivate/'+request.id);
    }

    company() {
        return api.get('/vms/module/pending');
    }
}

export default new VmsSiteService();