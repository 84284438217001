import LprSiteService from "@/services/lpr/site.service";

export const lprSite = {
    state: () => ({ 
       stateName: "LprSiteActivation", 
    }),
    namespaced: true,
    actions: {
        view({ commit }, request) {
            return LprSiteService.view(request)
        },
        company({ commit }) {
            return LprSiteService.company()
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        vendor({ commit }) {
            return LprSiteService.vendor()
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        create({ commit, state }, request) {
            return LprSiteService.create(request)
            .then(
                response => {
                    if(response.status === 200 && response.data.data) {
                        commit("paginator/insertRecordToPaginator", {
                            stateName: state.stateName,
                            dataToInsert: response.data.data
                        }, { root: true });
                    }
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        activate({ commit, state }, request) {
            return LprSiteService.activate(request)
            .then(
                response => {
                    if(response.status === 200 && response.data.data) {
                        commit("paginator/updateRecordInPaginator", {
                            stateName: state.stateName,
                            dataToUpdate: response.data.data,
                        }, { root: true });
                    }
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        deactivate({ commit, state }, request) {
            return LprSiteService.deactivate(request)
            .then(
                response => {
                    if(response.status === 200 && response.data.data) {
                        commit("paginator/updateRecordInPaginator", {
                            stateName: state.stateName,
                            dataToUpdate: response.data.data,
                        }, { root: true });
                    }
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        updateModule({ commit, state }, request) {
            return LprSiteService.updateModule(request)
            .then(
                response => {
                    if(response.status === 200 && response.data.data) {
                        commit("paginator/updateRecordInPaginator", {
                            stateName: state.stateName,
                            dataToUpdate: response.data.data,
                        }, { root: true });
                    }
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {},
    getters: {
        getStateName(state) {
            return state.stateName;
        }
    }
};