import { createRouter, createWebHistory } from 'vue-router';

import LeftSidebar from "@/components/LeftSidebar.vue";
import TopNavbar from "@/components/TopNavbar.vue";

import LprSiteActivationList from "@/views/Lpr/SiteActivation/List.vue";
import LprSiteActivationAdd from "@/views/Lpr/SiteActivation/Add.vue";
import LprSiteActivationView from "@/views/Lpr/SiteActivation/View.vue";

const routes = [
    { path: "/", name: "Login", components: { default: () => import("@/views/Login.vue") }, meta: { layout: "AuthLayout"} },
    { path: "/forgot-password", name: "ForgotPassword", components: { default: () => import("@/views/ForgotPassword.vue") }, meta: { layout: "AuthLayout"} },
    { path: "/reset-password", name: "ResetPassword", components: { default: () => import("@/views/ResetPassword.vue") }, meta: { layout: "AuthLayout"} },
    { path: "/home", name: "Home", components: { default: () => import("@/views/Home.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },
    { path: "/profile", name: "Profile", components: { default: () => import("@/views/Profile.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },
    { path: "/vms/site-activation", name: "Vms-SiteActivation", components: { default: () => import("@/views/Vms/SiteActivation/List.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },
    { path: "/vms/site-activation/add", name: "Vms-SiteActivation-Add", components: { default: () => import("@/views/Vms/SiteActivation/Add.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },
    { path: "/vms/site-activation/view/:id", name: "Vms-SiteActivation-View", components: { default: () => import("@/views/Vms/SiteActivation/View.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },

    { path: "/key-collection/site-activation", name: "KeyCollection-SiteActivation", components: { default: () => import("@/views/KeyCollection/SiteActivation/List.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },
    { path: "/key-collection/site-activation/add", name: "KeyCollection-SiteActivation-Add", components: { default: () => import("@/views/KeyCollection/SiteActivation/Add.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },
    { path: "/key-collection/site-activation/view/:id", name: "KeyCollection-SiteActivation-View", components: { default: () => import("@/views/KeyCollection/SiteActivation/View.vue"), LeftSidebar: () => import("@/components/LeftSidebar.vue"), TopNavbar: () => import("@/components/TopNavbar.vue") }, meta: { layout: "MainLayout" } },

    {
        path: "/lpr/site-activation",
        name: "Lpr-SiteActivation",
        components: {
            default: LprSiteActivationList,
            LeftSidebar: LeftSidebar,
            TopNavbar: TopNavbar
        },
        meta: { layout: "MainLayout" }
    },
    {
        path: "/lpr/site-activation/add",
        name: "Lpr-SiteActivation-Add",
        components: {
            default: LprSiteActivationAdd,
            LeftSidebar: LeftSidebar,
            TopNavbar: TopNavbar
        },
        meta: { layout: "MainLayout" }
    },
    {
        path: "/lpr/site-activation/view/:id",
        name: "Lpr-SiteActivation-View",
        components: {
            default: LprSiteActivationView,
            LeftSidebar: LeftSidebar,
            TopNavbar: TopNavbar
        },
        meta: { layout: "MainLayout" }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ["/", "/forgot-password", "/reset-password"];
    const authRequired = ! publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("authAccess");
   
    if(authRequired && ! loggedIn) {
        next("/");
    } else {
        
        next();
    }
});

router.onError(error => {
    if(/loading_chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
})

export default router