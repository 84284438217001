<template>
    <confirm-dialogue ref="confirmDialogueRef"></confirm-dialogue>
    <div class="row">
        <div class="col-xs-12 col-lg-9">
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="h4"><span>SITE ACTIVATION</span></div>
                        <div>
                            <button class="btn btn-primary" @click="onClickNewSiteActivation">ADD NEW</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th v-for="(value, index) in columnNames" :key="`${index}`" @click="onClickColumnFields(index)">
                                        <span class="sort-field">{{ value.name }}</span>
                                        <span v-show="displaySortColumnIndex(index)" class="sort-field">
                                            <font-awesome-icon :icon="['fas', displaySortIcon]" class="small" />
                                        </span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th v-for="(value, index) in columnNames" :key="`${index}`" @click="onClickColumnFields(index)">
                                        <span class="sort-field">{{ value.name }}</span>
                                        <span v-show="displaySortColumnIndex(index)" class="sort-field">
                                            <font-awesome-icon :icon="['fas', displaySortIcon]" class="small" />
                                        </span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr v-for="(value, index) in created" :key="`created-${index}`" class="row-created">
                                    <td>*</td>
                                    <td @click="onClickViewSiteActivation(value.id)" class="font-weight-bold cursor-pointer">{{ value.siteName }}</td>
                                    <td>{{ value.vendorName }}</td>
                                    <td>{{ displayDate(value.startDate) }}</td>
                                    <td>{{ displayDate(value.endDate) }}</td>
                                    <td class="text-center">{{ value.status }}</td>
                                    <td class="text-center">
                                        <button class="btn btn-secondary btn-sm btn-block" v-if="value.isActivated == 1 && value.status == 'Inactive'" @click="onClickViewSiteActivation(value.id)">EDIT</button>
                                        <button class="btn btn-danger btn-sm btn-block" v-if="value.isActivated == 1 && value.status == 'Active'" @click="onClickActivation(value.id, 'deactivate')">DEACTIVATE</button>
                                        <button class="btn btn-primary btn-sm btn-block" v-if="value.isActivated == 0 && value.status == 'Inactive'" @click="onClickActivation(value.id, 'activate')">ACTIVATE NOW</button>
                                    </td>
                                </tr>
                                <tr v-for="(value, index) in pageList" :key="index">
                                    <td>{{ pageInfo.startIndex + (index + 1) }}. </td>
                                    <td @click="onClickViewSiteActivation(value.id)" class="font-weight-bold cursor-pointer">{{ value.siteName }}</td>
                                    <td>{{ value.vendorName }}</td>
                                    <td>{{ displayDate(value.startDate) }}</td>
                                    <td>{{ displayDate(value.endDate) }}</td>
                                    <td class="text-center">{{ value.status }}</td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-secondary btn-sm btn-block" v-if="value.isActivated == 1 && value.status == 'Inactive'" @click="onClickViewSiteActivation(value.id)">EDIT</button>
                                        <button type="button" class="btn btn-danger btn-sm btn-block" v-if="value.isActivated == 1 && value.status == 'Active'" @click="onClickActivation(value.id, 'deactivate')">DEACTIVATE</button>
                                        <button type="button" class="btn btn-primary btn-sm btn-block" v-if="value.isActivated == 0 && value.status == 'Inactive'" @click="onClickActivation(value.id, 'activate')">ACTIVATE NOW</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <paginator-range :per-range="5" @onClickGoToPage="goToPage"></paginator-range>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-lg-3">
            <div class="card shadow">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">SEARCH <font-awesome-icon :icon="['fas', 'magnifying-glass']" /></h6>
                </div>
                <paginator-search @onClickSearch="startSearch" @onClickClear="clearSearch"></paginator-search>
            </div>
        </div>
    </div>
    <div class="mb-4"></div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import eventBus from "@/common/event-bus";

import PaginatorRange from "@/components/PaginatorRange";

import ConfirmDialogue from "@/components/modals/ConfirmDialogue";
import PaginatorSearch from "@/views/Lpr/SiteActivation/Search";
import manipulatePaginatorList from "@/composables/paginator-list";
import displayToastNotification from "@/composables/toast-notification";

export default {
    name: 'LprSiteActivation',
    components: { PaginatorRange, PaginatorSearch, ConfirmDialogue },
    setup(props, context) {

        const router = useRouter();

        const store = useStore();

        const defaultInputs = { siteName: "", vendorName: "" };

        const columnFields = [
            "_siteName",
            "_vendorName",
            "_from",
            "_to",
            "_status"
        ];

        const columnNames = [
            { name: "Site Name", attribute: "" },
            { name: "Vendor Name", attribute: "" },
            { name: "Start Date", attribute: "" },
            { name: "End Date", attribute: "" },
            { name: "Status", attribute: "" }
        ]

        const confirmDialogueRef = ref();

        let isLoading = ref(false);

        let toastNotification = displayToastNotification();

        let paginatorList = manipulatePaginatorList(defaultInputs, columnFields);
        paginatorList.setStateName(store.getters['lprSite/getStateName']);
        paginatorList.startup();

        const startSearch = (inputs) => {
            paginatorList.goSearch({ params: { 
                _siteName: inputs.siteName, 
                _vendorName: inputs.vendorName,
                _from: inputs.from,
                _to: inputs.to,
                _status: inputs.status
            }, search: inputs });
        }

        const clearSearch = (inputs) => {
            paginatorList.goSearch({ params: inputs, search: defaultInputs });
        } 

        const goToPage = (page) => {
            paginatorList.goToPage(page);
        }

        const displaySortColumnIndex = (index) => {
            if(columnFields[index] == paginatorList.getSortColumnIndex()) {
                return true;
            }
            return false;
        };

        const displaySortIcon = computed(function() {
            return paginatorList.getSortIcon();
        });

        const displayDate = (dt) => {
            return moment(dt).format("DD/MM/YYYY");
        };

        const onClickViewSiteActivation = (id) => {
            router.push({ name: 'Lpr-SiteActivation-View', params: { id: id } });
        }

        const onClickNewSiteActivation = () => {
            router.push({ name: 'Lpr-SiteActivation-Add' });
        }

        const onClickActivation = (id, type) => {
            const requestData = {
                id: id,
                type: type
            }
            const message = (type == "activate") ? "Are you sure you want to activate this site? The start date will automatically changed to today's date." : "Are you sure you want to deactivate this site?"; 
            confirmDialogueRef.value.onClickOpen(message, requestData);
        }

        const onClickActivationSubmit = async (id, type) => {

            isLoading.value = true;

            await store.dispatch("lprSite/"+type, { id: id })
            .then(
                (response) => {
                    if(response.data.data && response.data.success == 1) {
                        toastNotification.updateSuccessNotification(); 
                    } else {
                        toastNotification.errorNotification(response.data.error.message);
                    }
                },
                (error) => { 
                    if(error.response && error.response.data.error) {
                        toastNotification.errorNotification(error.response.data.error.message);
                    } else {
                        toastNotification.errorNotification(error.response.data.message);
                    }
                }
            ).then(() => {
                eventBus.$emit("closeDialogue");
                isLoading.value = false;
            }); 
        }

        const onClickColumnFields = (index) => {
            paginatorList.goSort({ field: columnFields[index] });
        }

        onMounted(() => {
            eventBus.$on("onClickPopupSubmit", (args) => {
                onClickActivationSubmit(args.id, args.type); 
            });
        })

        onUnmounted(() => {
            eventBus.$off("onClickPopupSubmit");
        })

        return { 
            ...paginatorList,
            ...toastNotification,
            isLoading,
            columnNames,
            startSearch,
            clearSearch,
            goToPage,
            displaySortIcon,
            displaySortColumnIndex,
            displayDate,
            onClickViewSiteActivation, 
            onClickNewSiteActivation, 
            onClickActivation,
            onClickColumnFields,
            confirmDialogueRef
        }
    }
}
</script>