import api from '@/services/api';

class KeyCollectionSiteService {
    view(request) {
        return api.get('/keycollection/activation/'+request.id);
    }

    create(request) {
        return api.post('/keycollection/activation', request);
    }

    updateModule(request) {
        return api.put('/keycollection/activation/'+request.id, request.body);
    }

    activate(request) {
        return api.put('/keycollection/activation/activate/'+request.id);
    }

    deactivate(request) {
        return api.put('/keycollection/activation/deactivate/'+request.id);
    }

    company() {
        return api.get('/keycollection/activation/pending');
    }
}

export default new KeyCollectionSiteService();