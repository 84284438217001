<template>
  <div id="wrapper">
    <loading-bar></loading-bar>
    <RouterView name="LeftSidebar"></RouterView>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <RouterView name="TopNavbar"></RouterView>
        <breadcrumb></breadcrumb>
        <div class="container-fluid">
          <RouterView></RouterView>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from "@/components/LoadingBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
    name: 'MainLayout',
    components: { LoadingBar, Breadcrumb },
}
</script>