import moment from 'moment';

export default function formSiteActivation() {
    
    const onUpdateIsMigration = (isActivated, isMigration) => {            
        if(isActivated == 1) {
            return false;
        } else {
            if(isMigration) {
                return true
            }
        }
        return false;
    }
    
    const onUpdateCutoffDate = (date) => {
        if(date) { 
            const sdt = moment(date).add(1, 'd').toString();
            const edt = moment(new Date(sdt)).add(10, 'y').toString();
            return {
                "startDate": sdt,
                "endDate": edt
            }
        } 
    }
    
    const onUpdateStartDate = (date) => {
        if(date) { 
            return moment(date).add(10, 'y').toString(); 
        }
    }
    
    const populateDefaultGeneralSettings = (data) => {
        let result = [];
        for(let i = 0; i < data.length; i++) {
            const inputObj = JSON.parse(data[i]['settingField']);
            const inputObjType = inputObj['inputType'];
            const inputObjValue = inputObj['inputValue'];
            const totalInput = Object.keys(inputObjType).length;
    
            let thisInput = [];
                
            for(let j = 0; j < totalInput; j++) {
                const type = inputObjType[j];
                const valueList = inputObjValue[j];
                const split = data[i]['settingValue'].split('|');
                const value = split[j] ?? "";
                   
                thisInput.push({
                    'type': type,
                    'default': valueList,
                    'value': value
                });
            }
    
            result.push({
                'id': data[i]['settingId'],
                'name': data[i]['settingName'],
                'isEditable': data[i]['isEditable'],
                'input': thisInput
            })
        }
        return result;
    }
    
    const populateDefaultVisitType = (data, action) => {
        let result = [];
        for(let i = 0; i < data.length; i++) {
            const accountType = data[i]['accountType'];
            let accountTypeIsActiveAdmin = true;
            let accountTypeIsActiveResident = true;
            let accountTypeIsActiveGuard = true;
    
            if(action != "add") {
                if(! Object.keys(accountType).includes('A') || accountType['A'] != 1) {
                    accountTypeIsActiveAdmin = false;
                }
        
                if((! Object.keys(accountType).includes('R') && ! Object.keys(accountType).includes('T') && ! Object.keys(accountType).includes('F')) || (accountType['R'] != 1 || accountType['T'] != 1 || accountType['F'] != 1)) {
                    accountTypeIsActiveResident = false;
                }
        
                if(! Object.keys(accountType).includes('Z') || accountType['Z'] != 1) {
                    accountTypeIsActiveGuard = false;
                }
            }
         
            result.push({
                id: data[i]['visitTypeBuildingId'],
                defaultId: data[i]['defaultId'],
                name: data[i]['typeName'],
                admin: accountTypeIsActiveAdmin,
                resident: accountTypeIsActiveResident,
                guard: accountTypeIsActiveGuard,
                attachment: data[i]['withAttachment'] ? true : false,
                position: data[i]['position'],
                isEForm: data[i]['defaultId'] == 7 || data[i]['defaultId'] == 13 ? 'Y' : 'N',
                isDeletable: data[i]['isDeletable'] == 1 ? true : false,
                isDeleted: data[i]['isDeleted'] == 1 ? true : false,
                toDelete: false,
            })
        }
        return result;
    }
    
    const populateDefaultSubModule = (data) => {
        let result = [];
        for(let i = 0; i < data.length; i++) {
            result.push({
                id: data[i]['subModuleId'],
                moduleName: data[i]['subModuleName'],
                startDate: data[i]['startDate'],
                endDate: data[i]['endDate'],
                isActive: data[i]['isActive'],
                checked: data[i]['isSubscribed'] == 1 ? true : false,
            })
        }
        return result;
    }
    
    return { 
        onUpdateIsMigration,
        onUpdateCutoffDate,
        onUpdateStartDate,
        populateDefaultGeneralSettings,
        populateDefaultVisitType,
        populateDefaultSubModule
    };
}