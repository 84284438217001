import api from '@/services/api';

class KeyCollectionSettingService {
   
    generalSettings(request) {
        return api.get('/general-settings/kc');
    }

    updateGeneralSettings(request) {
        return api.put('/general-settings/kc?site='+request.site, request.body);
    }
}

export default new KeyCollectionSettingService();