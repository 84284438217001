<template>
    <popup-modal ref="popupModalRef">
        <template #title></template>
        <template #message>
            <div class="message">{{ message }}</div>
        </template>
        <template #actions></template>
    </popup-modal>
</template>

<script>
import { ref, computed } from "vue";
import PopupModal from "@/components/modals/PopupModal";

export default {
    name: "ConfirmDialogue",
    components: { PopupModal },
    setup(props, context) {

        const message = ref("");

        const popupModalRef = ref();

        const onClickOpen = (msg, req) => {
            message.value = msg;
            popupModalRef.value.onClickOpenDialogue(req);
        };

        return { message, popupModalRef, onClickOpen };
    }
}

</script>