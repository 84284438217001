<template>
    <div class="row">
        <div class="col-12">
            <div class="d-sm-flex align-items-center justify-content-between mb-3">
                <div class="h4"><span>SITE ACTIVATION</span></div>
                <button type="button" class="btn btn-link btn-fontawesome"><font-awesome-icon :icon="['far', 'circle-xmark']" class="h3" @click="backToList" /></button>
            </div>
        </div>
    </div>
    <edit-main-site v-if="! isLoading"></edit-main-site>
    <div class="mb-3"></div>
</template>

<script>
import { ref, reactive, provide } from "vue";
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { DateTimeClass } from '@/classes/datetime-class';
import formSiteActivation from "@/composables/lpr/forms/form-site-activation";
import displayToastNotification from "@/composables/toast-notification";
import EditMainSite from "@/views/Lpr/SiteActivation/EditMainSite";

export default {
    name: "ViewSiteActivation",
    components: { EditMainSite },
    setup(props, { emit }) {

        const router = useRouter();

        const route = useRoute();

        const store = useStore();

        const toastNotification = displayToastNotification();

        const siteActivation = formSiteActivation();

        const isLoading = ref(true);

        const defaultSite = reactive({
            companyId: 0,
            siteName: "",
            vendorName: "",
            activatedStatus: null,
            isActivated: 0,
            activatedDate: null,
            isActive: false,
            cutoffDate: null,
            startDate: null,
            endDate: null,
            createdBy: "",
            createdAt: null,
            updatedBy: "",
            updatedAt: null,
        });
        
        provide("defaultSite", defaultSite);

        const defaultTapwaySettings = ref([]);
        provide("defaultTapwaySettings", defaultTapwaySettings);

        const backToList = () => {
            router.push({ name: 'Lpr-SiteActivation' })
        }

        const fetchMain = () => {
           
            store.dispatch("lprSite/view", { id: route.params.id }).then(
                response => {
                    if(response.status === 200) {
                        // Module
                        const moduleInfo =  response.data.data.site;

                        defaultSite.companyId = moduleInfo.companyId;
                        defaultSite.siteId = moduleInfo.siteId;
                        defaultSite.siteName = moduleInfo.siteName;
                        defaultSite.vendorName = moduleInfo.vendorName;
                        defaultSite.activatedStatus = moduleInfo.isActivated == 1 ? 'Yes' : 'No';
                        defaultSite.activatedDate = moduleInfo.activatedAt ? DateTimeClass.convertUTCToTimezone(moduleInfo.activatedAt) : "N/A";
                        defaultSite.isActive = moduleInfo.isActive;
                        defaultSite.isActivated = moduleInfo.isActivated;
                        defaultSite.startDate = new Date(moduleInfo.startDate);
                        defaultSite.endDate = new Date(moduleInfo.endDate);
                        defaultSite.createdAt = DateTimeClass.convertUTCToTimezone(moduleInfo.createdAt) 
                        defaultSite.updatedAt = DateTimeClass.convertUTCToTimezone(moduleInfo.updatedAt) 
                        defaultSite.createdBy = moduleInfo.createdByAccName;
                        defaultSite.updatedBy = moduleInfo.updatedByAccName;
                        
                        if (response.data.data.tapwaySetting !== null) {
                            defaultTapwaySettings.value = siteActivation.populateDefaultTapwaySettings(response.data.data.tapwaySetting);
                        }
                        defaultTapwaySettings.value.activationId = moduleInfo.id;

                        isLoading.value = false;
                    }
                },
                error => { 
                    if(error.response && error.response.data) {
                        toastNotification.errorNotification(error.response.data.error.message);
                    } 
                }
            );
        }

        fetchMain();
      
        return { backToList, isLoading, defaultSite };
    }
}
</script>