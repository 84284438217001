<template>
    <div v-show="Object.keys(errors).length > 0" class="alert alert-danger" role="alert">
        Whoops! There were some problems with your input
    </div>
</template>

<script>
export default {
    name: 'ValidationErrorMessage',
    props: {
        errors: {
            type: Object,
        },
    },
}
</script>