import ProfileService from "@/services/profile.service";

export const profile = {
    namespaced: true,
    actions: {
        fetchProfile({ commit }) {
            return ProfileService.fetchProfile()
            .then(
                response => {
                    commit("auth/user", response, { root: true });
                    return Promise.resolve(response); 
                },
                error => { return Promise.reject(error); }
            )
        },
        changePassword({ commit }, request) {
            return ProfileService.changePassword(request)
            .then(
                response => { return Promise.resolve(response); },
                error => { return Promise.reject(error); }
            )
        },
    },
    mutations: {},
};