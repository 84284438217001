import axios from "axios";
import ApiRoute from "./api.route";

const instance = axios.create({
    baseURL: ApiRoute.getApiBaseUrl(),
    headers: {
        "Content-Type": "application/json"
    },
    _retry: false,
});

export default instance;