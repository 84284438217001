<template>
    <transition name="fade">
        <div class="popup-modal" v-if="isVisible" :class="{ 'open': isVisible }">
            <div class="container">
                <slot name="title"></slot>
                <slot name="message"></slot>
                <slot name="actions">
                    <div class="buttons">
                        <button type="button" class="btn btn-secondary" @click="onClickCancel">Cancel</button>
                        <button type="button" class="btn btn-primary" @click="onClickSubmit">OK</button>
                    </div>
                </slot>
            </div>
        </div>
    </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import eventBus from "@/common/event-bus";

export default {
    name: "PopupModal", 
    setup(props, context) {

        const isVisible = ref(false);

        const isLoading = ref(false);

        const requestData = ref({});

        const onClickCancel = () => {
            if(! isLoading.value) {
                isVisible.value = false;
            }
        }

        const onClickSubmit = () => {
            if(! isLoading.value) {
                isLoading.value = true;
                eventBus.$emit("onClickPopupSubmit", requestData.value)
            }
        }

        const onClickOpenDialogue = (req) => {
            if(! isLoading.value) {
                requestData.value = req;
                isVisible.value = true;
            }
        }

        const closeDialogue = () => {
            requestData.value = {};
            isVisible.value = false;
            isLoading.value = false;
        }

        onMounted(() => {
            eventBus.$on("closeDialogue", () => { closeDialogue(); });
        })

        onUnmounted(() => {
            eventBus.$off("closeDialogue");
        })

        return { isVisible, requestData, onClickCancel, onClickSubmit, onClickOpenDialogue, closeDialogue }
    }
}
</script>

