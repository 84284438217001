import api from '@/services/api';

class ProfileService {
    
    fetchProfile() {
        return api.get('/profile')
        .then(response => {
            if(response.status === 200) {
                return this.storeUserProfile(response.data.data);
            }
        });
    }

    changePassword(request) {
        return api.put('/profile/change-password', request);
    }

    storeUserProfile(user) {
        localStorage.setItem("user", JSON.stringify(user));
        return user;
    }
}

export default new ProfileService();