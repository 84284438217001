<template>
    <div class="paginator">
        <button @click="onClickPrevPage" class="prev">prev</button>
        <button v-for="(item, index) in buttonRange" :key="index" @click="onClickGoToPage(item)" :class="{ 'selected': (item == currentPage) }">{{ item }}</button>
        <button @click="onClickNextPage" class="next">next</button>
    </div>
</template>

<script>
import { ref, computed, inject } from "vue";

export default {
    name: "PaginatorRange",
    props: {
        perRange: {
            type: Number,
            default: 5,
        },
    },
    setup(props, context) {
        
        let currentPage = inject("currentPage");
        let totalPage = inject("totalPage");
        let totalRecords = inject("totalRecords");
        let limit = inject("limit");

        const buttonRange = computed(function() {
            if(totalPage.value > props.perRange) {
                let prepend = [1];
                let nums = [];
                let suspend = [totalPage.value];

                if(currentPage.value > 0 && currentPage.value < props.perRange) {
                    prepend = [];
                    nums = Array.from({length: props.perRange}, (v, i) => i + 1);
                } else if((totalPage.value - currentPage.value) >= 0 && (totalPage.value - currentPage.value) < (props.perRange - 1) ) {
                    nums = Array.from({length: props.perRange}, (v, i) => (i + (totalPage.value - props.perRange)) + 1)
                    suspend = [];
                } else {
                    const mid = Math.floor(props.perRange / 2);
                    
                    for(let loopI = 0; loopI < props.perRange; loopI++) {
                        nums.push((currentPage.value - mid) + loopI);
                    }
                }

                if(nums[0] - 1 > 1) {
                    prepend.push('...');
                }
                
                if(totalPage.value - nums[nums.length - 1] > 1) {
                    suspend.unshift('...');
                }

                return prepend.concat(nums).concat(suspend);
            }
            else {
                return Array.from({length: totalPage.value}, (v, i) => i + 1);
            }
        });

        const onClickNextPage = () => {
            if(currentPage.value !== Math.ceil(totalRecords.value / limit.value)) {
                currentPage.value += 1;
            }
            context.emit("onClickGoToPage", currentPage.value);
        }

        const onClickPrevPage = () => {
            if(currentPage.value !== 1) {
                currentPage.value -= 1;
            }
            context.emit("onClickGoToPage", currentPage.value);
        };
        

        const onClickGoToPage = (page) => {
            context.emit("onClickGoToPage", page);
        }
       
        return { buttonRange, currentPage, onClickNextPage, onClickPrevPage, onClickGoToPage };
    },
    emits: ["onClickGoToPage"]
}
</script>