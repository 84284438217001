const appLayout = JSON.parse(localStorage.getItem("appLayout"));
const initialState = appLayout ? appLayout : { leftSidebarToggleClass: "", leftSidebarIcon: "angle-left", };

export const layout = {
    namespaced: true,
    state: initialState,
    actions: {
        update({ commit }, layout) {
            if(layout.key == "leftSidebar") {
                commit("updateLeftSidebarToggle", layout.className);
                commit("updateLeftSidebarIcon");
            }
            commit("updateAppLayout");
        }
    },
    mutations: {
        updateLeftSidebarToggle(state, className) {
            state.leftSidebarToggleClass = ! state.leftSidebarToggleClass ? className : "";
        },
        updateLeftSidebarIcon(state) {
            state.leftSidebarIcon = ! state.leftSidebarToggleClass ? "angle-left" : "angle-right";
        },
        updateAppLayout(state) {
            localStorage.setItem("appLayout", JSON.stringify(Object.assign(initialState, state)));
        }
    }
}