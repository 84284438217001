import api from '@/services/api';

class PaginatorService {
    vmsSiteActivation(request) {
        const params = this.populatePaginatorRequest(request);
        return api.get("/vms/module", { params });
    }

    keyCollectionSiteActivation(request) {
        const params = this.populatePaginatorRequest(request);
        return api.get("/keycollection/activation", { params });
    }
    
    lprSiteActivation(request) {
        const params = this.populatePaginatorRequest(request);
        return api.get("/lpr/activation", { params });
    }

    populatePaginatorRequest(detail) {
        let request = {};

        if(detail.info) {
            Object.assign(request, detail.info)
        }

        if(detail.params) {
            Object.assign(request, detail.params);
        }
        
        return request;
    }
    
    populatePaginatorResponse(response, params, search) {

        const result = { pageList: [], pageInfo: {}, pageParams: {}, pageSearch: {}, created: [] };
        
        if(response.status === 200) {
            const data = response.data.data.list ? response.data.data.list : null;
            const info = response.data.data.paginator ? response.data.data.paginator : null;
            
            if(data && info) {
                result.pageList = data;
                result.pageInfo = {
                    totalPage: info.totalPage,
                    totalRecords: info.totalRecords,
                    currentPage: info.currentPage,
                    limit: info.limit,
                    startIndex: info.startIndex,
                    endIndex: info.endIndex,
                    orderBy: info.order,
                    sort: info.by,
                }
            }

            Object.assign(result.pageParams, params);
            Object.assign(result.pageSearch, search);
        }
      
        return result;
    }
}

export default new PaginatorService();
