import AuthService from "@/services/auth.service";

const authAccess = JSON.parse(localStorage.getItem("authAccess"));
const user = JSON.parse(localStorage.getItem("user"));
const initialState = authAccess ? { status: { loggedIn: true }, authAccess, user: user } : { status: { loggedIn: false }, authAccess: null, user: null }

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user)
            .then(
                response => {
                    commit("loginSuccess", response);
                    return Promise.resolve(response);
                },
                error => {
                    commit("logout");
                    return Promise.reject(error);
                }
            )
        },

        logout({ commit }) {
            return AuthService.logout()
            .then(
                response => { return Promise.resolve(response); },
                error => { return Promise.reject(error); }
            )
            .then(() => { commit("logout"); })
        },

        forgotPassword({ commit }, user) {
            return AuthService.forgotPassword(user)
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },

        resetPassword({ commit }, user) {
            return AuthService.resetPassword(user)
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },

        refreshToken({ commit }, response) {
            const authAccess = AuthService.successAuthAccess(response);
            commit("loginSuccess", authAccess);
        },

        requestRefreshToken({ commit }, token) {
            return AuthService.requestRefreshToken(token);
        },

        forceLogout({ commit} ) {
            AuthService.resetAuthAccess();
            commit("logout");
        }
    },
    mutations: {
        loginSuccess(state, response) {
            state.status = { loggedIn: true };
            state.authAccess = response;
        },
        logout(state) {
            state.status = { loggedIn: false };
            state.authAccess = null;
        },
        user(state, user) {
            if(user) {
                state.user = user;
            }
        }
    },
};