<template>
    <div class="loader" :class="displayClassName">
        <div>
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>        
    </div>
</template>

<script>
import eventBus from "@/common/event-bus";
import { ref, onUnmounted, onMounted } from "vue";

export default {
    name: "LoadingBar",
    setup() {
        let displayClassName = ref("d-none");

        const updateLoadingBar = (args) => {
            displayClassName.value = args;
        }

        onMounted(() => {
            eventBus.$on("updateLoadingBar", (args) => { updateLoadingBar(args); });
        })

        onUnmounted(() => {
            eventBus.$off("updateLoadingBar");
        })

        return { displayClassName, updateLoadingBar}
    }
}

</script>