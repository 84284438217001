import KeyCollectionSettingService from "@/services/keycollection/setting.service";

export const keyCollectionSetting = {
    namespaced: true,
    actions: {
        generalSettings({ commit }, request) {
            return KeyCollectionSettingService.generalSettings(request)
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },

        updateGeneralSettings({ commit }, request) {
            return KeyCollectionSettingService.updateGeneralSettings(request)
            .then(
                response => { 
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {},
};