<template>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Sidebar Toggle (Topbar) -->
       
        <button type="button" id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" @click="onClickSidebar()">
            <i class="fa fa-bars"></i>
        </button>

        <div>{{  displayDevMode() }}</div>
      
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow" @click.prevent="onClickUser">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">My Account</span>
                    <font-awesome-icon :icon="['fas', 'circle-user']" class="large" />
                </a>
                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <a class="dropdown-item" href="#" @click.prevent="onClickProfile">
                        <font-awesome-icon :icon="['fas', 'user']" class="small" />
                        Profile
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" data-toggle="modal" @click.prevent="onClickLogout">
                        <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="small" />
                        Logout
                    </a>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'TopNavbar',
    methods: {
        onClickUser() {
            const element = document.querySelector("#userDropdown + div");
            if(element) {
                if(element.classList.contains("show")) {
                    element.classList.remove("show");
                } else {
                    element.classList.add("show");
                }
            }
        },
        onClickLogout() {
            this.$store.dispatch("auth/logout").then(
                () => {
                    this.$router.push({ name: 'Login' })
                }
            )
        },
        onClickProfile() {
            this.$router.push({ name: 'Profile' })
        },
        onClickSidebar() {
            this.$store.dispatch('layout/update', { key: "leftSidebar", className: "toggled", icon: "angle-left" });
        },
        displayDevMode() {
            return process.env.VUE_APP_MODE;
        }
    },
}
</script>