import VmsSettingService from "@/services/vms/setting.service";

export const vmsSetting = {
    namespaced: true,
    actions: {
        generalSettings({ commit }, request) {
            return VmsSettingService.generalSettings(request)
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        visitType({ commit }) {
            return VmsSettingService.visitType()
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        subModule({ commit }) {
            return VmsSettingService.subModule()
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        createVisitType({ commit }, request) {
            return VmsSettingService.createVisitType(request)
            .then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        
        updateGeneralSettings({ commit }, request) {
            return VmsSettingService.updateGeneralSettings(request)
            .then(
                response => { 
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        updateVisitType({ commit }, request) {
            return VmsSettingService.updateVisitType(request)
            .then(
                response => { 
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        updateSubModule({ commit }, request) {
            return VmsSettingService.updateSubModule(request)
            .then(
                response => { 
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {},
};