import { createApp } from 'vue'
import app from './App.vue'
import router from './router'
import store from './store'
import setupInterceptors from '@/services/setup.interceptors';
import './assets/css/fontawesome-5.14.5/css/all.css';
import './assets/css/font.css';

// Import the fontawesome core 
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

// Toast Notification
import toast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// Date Picker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

library.add(fas, fab, far);
setupInterceptors(store);

createApp(app)
  .use(router)
  .use(store)
  .use(toast)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component('Datepicker', Datepicker)
  .mount('#app');
