import { useToast } from 'vue-toast-notification';
import { useRouter } from 'vue-router';

export default function displayToastNotification() {
  
    const toast = useToast();

    const router = useRouter()

    const insertSuccessNotification = () => {
        toast.open({
            message: "Save successfully",
            type: "success",
            position: "top",
            duration: 3000,
            dismissible: true,
        })
    };

    const forgotPasswordSuccessNotification = () => {
        toast.open({
            message: "A password reset message was sent to your email address. Please click the link in that message to reset your password.",
            type: "success",
            position: "top",
            duration: 0,
            dismissible: true,
        })
    };

    const resetPasswordSuccessNotification = () => {
        toast.open({
            message: "You can now sign in with your new password.",
            type: "success",
            position: "top",
            duration: 0,
            dismissible: true,
        })
    };
       
    const updateSuccessNotification = () => {
        toast.open({
            message: "Data updated successfully",
            type: "success",
            position: "top",
            duration: 3000,
            dismissible: true,
        })
    };

    const errorNotification = (message) => {
        toast.open({
            message: message,
            type: "error",
            position: "top",
            duration: 0,
            dismissible: true,
        })
    };

    const multiLoginNotification = () => {
        toast.open({
            message: "Your sessions has expired. Please login again.",
            type: "error",
            position: "top",
            duration: 3000,
            dismissible: true,
            onDismiss: () => { 
             router.push({ name: 'Login' });
            }
        })
    }

    const clearToast = () => {
        toast.clear();
    }

    return { 
        insertSuccessNotification, 
        updateSuccessNotification,
        forgotPasswordSuccessNotification,
        resetPasswordSuccessNotification,
        errorNotification, 
        multiLoginNotification,
        clearToast
    };
}