const ApiRoute = (function() {
   
    function _getApiBaseUrl() {
        return process.env.VUE_APP_BASE_URL;
    }

    function _getLocalStorageToken() {
        const authAccess = JSON.parse(localStorage.getItem("authAccess"));
        if(authAccess && authAccess.accessToken) {
            return authAccess.accessToken;
        }
        return null;
    } 
   
    return {
        getApiBaseUrl: _getApiBaseUrl,
        getLocalStorageToken: _getLocalStorageToken,
    }

})();

export default ApiRoute;