<template>
    <div id="auth" class="bg-gradient-login">
        <RouterView v-slot="{ appLayout }"></RouterView>
    </div>
</template>

<script>
export default {
    name: 'AuthLayout'
}
</script>