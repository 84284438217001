import PaginatorService from "@/services/paginator.service";

// State from store module state
const initialState = { VmsSiteActivation: {}, KeyCollectionSiteActivation: {}, LprSiteActivation: {} };

export const paginator = {
    namespaced: true,
    state: initialState,
    actions: {
        vmsSiteActivationList({ commit }, request) {
            return PaginatorService.vmsSiteActivation(request)
            .then(
                response => {
                    commit("updateAppPaginator", { stateName: request.name, response: response, params: request.params, search: request.search });
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        keyCollectionSiteActivationList({ commit }, request) {
            return PaginatorService.keyCollectionSiteActivation(request)
            .then(
                response => {
                    commit("updateAppPaginator", { stateName: request.name, response: response, params: request.params, search: request.search });
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        lprSiteActivationList({ commit }, request) {
            return PaginatorService.lprSiteActivation(request)
            .then(
                response => {
                    commit("updateAppPaginator", { stateName: request.name, response: response, params: request.params, search: request.search });
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {
        updateAppPaginator(state, response) {
            if(state[response.stateName]) {
               state[response.stateName] = PaginatorService.populatePaginatorResponse(response.response, response.params, response.search);
            }
        },
        insertRecordToPaginator(state, response) {
            if(state[response.stateName]) {
                state[response.stateName].created.push(response.dataToInsert);
            }
        },
        updateRecordInPaginator(state, response) {
            if(state[response.stateName]) {
                // Create
                const indexCreate = state[response.stateName].created.findIndex( item => item.id == response.dataToUpdate.id);
                if(indexCreate >= 0) {
                    state[response.stateName].created[indexCreate] = response.dataToUpdate;
                }

                // PageList
                const indexList = state[response.stateName].pageList.findIndex( item => item.id == response.dataToUpdate.id);
                if(indexList >= 0) {
                    state[response.stateName].pageList[indexList] = response.dataToUpdate;
                }
            }
        }
    },
    getters: {
        getVmsSiteActivationState: function(state) {
            return state.VmsSiteActivation;
        },
        getKeyCollectionSiteActivationState: function(state) {
            return state.KeyCollectionSiteActivation;
        },
        getLprSiteActivationState: function(state) {
            return state.LprSiteActivation;
        }
    }
}
