import axios from "axios";
import axiosInstance from "@/services/api";
import ApiRoute from "@/services/api.route";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = ApiRoute.getLocalStorageToken();
            if(token) {
                config.headers["Authorization"] = "Bearer "+token;
            }
            return config;
        },
        (error) => {
           return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            if(response.status === 200 || response.status === 201) {
                return response;
            } 
            return Promise.reject(err);
        },
        async (err) => {
            let originalConfig = err.config;
           
            if(err.response && err.response.status === 401) {
                if(originalConfig.url != "/login" && ! originalConfig._retry) {
                    originalConfig._retry = true;                    
                    try {
                        const token = ApiRoute.getLocalStorageToken();
                        const rs = await store.dispatch("auth/requestRefreshToken", token);
                        
                        if(rs.status === 201) {
                            originalConfig._retry = false;
                            await store.dispatch("auth/refreshToken", rs.data);
                            return axiosInstance(originalConfig);
                        } else {
                            return Promise.reject(err);
                        }
                    } catch {
                        return Promise.reject(err);
                    }
                }
                return Promise.reject(err);
            }
            return Promise.reject(err);
        }
    );
}

export default setup;