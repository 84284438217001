<template>
    <div class="card-body">
        <div class="pb-3">
            <label>Sites</label>
            <input type="text" class="form-control" v-model="inputs.siteName"/>
        </div>
        <div class="pb-3">
            <label>Start Date</label>
            <Datepicker 
                name="from"
                v-model="inputs.from"
                :format="'dd/MM/yyyy'"
                :enableTimePicker="false" 
                autoApply 
            />
        </div>
        <div class="pb-3">
            <label>End Date</label>
            <Datepicker 
                name="to"
                v-model="inputs.to"
                :format="'dd/MM/yyyy'"
                :enableTimePicker="false" 
                autoApply 
            />
        </div>
        <div class="pb-3">
            <label>Status</label>
            <Field :id="`status`" :name="`status`" class="form-control" as="select" v-model="inputs.status">
                <option value="">-- Select One --</option>
                <option v-for="option in statusOptions" :value="option.key">{{ option.text }}</option>
            </Field>
        </div>
        <button type="button" class="btn btn-primary btn-block" @click="onClickSearch">SEARCH</button>
        <button type="button" class="btn btn-secondary btn-block" @click="onClickClear">CLEAR</button>
    </div>
</template>

<script>
import { Field } from 'vee-validate';
import manipulatePaginatorSearch from "@/composables/paginator-search";
import Datepicker from '@vuepic/vue-datepicker';
import moment from 'moment';

export default {
    name: 'SiteActivationSearch',
    components: { Datepicker, Field },
    setup(props, context) {
        const statusOptions = [{ text: 'Active', key: 'active' }, { text: 'Inactive', key: 'inactive' }];

        const onClickSearch = () => {
            let momentFrom = paginatorSearch.inputs.value.from;
            let momentTo = paginatorSearch.inputs.value.to;

            if (paginatorSearch.inputs.value.from !== undefined) {
                momentFrom = moment(paginatorSearch.inputs.value.from);
            }
            if (paginatorSearch.inputs.value.to !== undefined) {
                momentTo = moment(paginatorSearch.inputs.value.to);
            }

            if (paginatorSearch.inputs.value.from !== undefined && paginatorSearch.inputs.value.to !== undefined) {
                if (momentFrom.isAfter(momentTo)) {
                    alert('Selected end date shall not be before start date.')
                }
            }

            if (paginatorSearch.inputs.value.from !== undefined) {
                paginatorSearch.inputs.value.from = momentFrom.format('YYYY-MM-DD')    
            }
            
            if (paginatorSearch.inputs.value.to !== undefined) {
                paginatorSearch.inputs.value.to = momentTo.format('YYYY-MM-DD')
            }

            context.emit("onClickSearch", paginatorSearch.inputs.value);
        }

        const onClickClear = () => {
            paginatorSearch.inputs.value = {};
            console.log(paginatorSearch.inputs.value.from);
            context.emit("onClickClear", paginatorSearch.inputs.value);
        }

        const paginatorSearch = manipulatePaginatorSearch();

        return {
            ...paginatorSearch,
            onClickSearch,
            onClickClear,
            Datepicker,
            Field,
            statusOptions
        };
    },
    emits: ["onClickSearch", "onClickClear"]
}
</script>