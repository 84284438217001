import moment from 'moment';

export default function formSiteActivation() {
    
    const onUpdateStartDate = (date) => {
        if(date) { 
            return moment(date).add(10, 'y').toString(); 
        }
    }

    const populateDefaultTapwaySettings = (data) => {
        let result = [];
        result.username = data.username;
        result.serverIp = data.serverIp;
        result.dashboardUrl = data.dashboardUrl;
        return result;
    }
    
    return { 
        onUpdateStartDate,
        populateDefaultTapwaySettings
    };
}